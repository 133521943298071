@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.cdnfonts.com/css/volte);
.monogram {
  border: 1px solid rgb(var(--rgbText));
  border-radius: 50%;
  fill: var(--colorTextTitle);
  transition: fill var(--durationL) ease;
}

.monogram__highlight {
  fill: rgb(var(--rgbAccent));
  opacity: 0;
  transform: scale3d(1, 0, 1);
  transform-origin: top;
  transition: opacity 0.1s ease var(--durationM)
}

@media (prefers-reduced-motion: no-preference) {
    .monogram__highlight {
      transition: transform var(--durationM) var(--bezierFastoutSlowin),
        opacity 0.1s ease var(--durationM);
    }
  }

a:focus .monogram__highlight,
a:hover .monogram__highlight,
.monogram:hover .monogram__highlight {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transform-origin: bottom;
  transition: opacity 0.1s ease
}

@media (prefers-reduced-motion: no-preference) {
    a:focus .monogram__highlight, a:hover .monogram__highlight, .monogram:hover .monogram__highlight {
      transition: transform var(--durationM) var(--bezierFastoutSlowin), opacity 0.1s ease;
    }
  }

.icon {
  display: block;
  fill: currentColor;
}

.visually-hidden {
  position: absolute;
}

.visually-hidden--hidden,
.visually-hidden--show-on-focus:not(:focus) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  word-wrap: normal;
}

.loader {
  display: flex;
  justify-content: center;
  width: var(--size);
  height: var(--size);
}

.loader-text {
  color: inherit;
  font-size: var(--fontSizeBodyS);
  font-weight: var(--fontWeightMedium);
}

.loader__content {
  display: grid;
  grid-template-columns: repeat(3, var(--spanSize));
  grid-gap: var(--gapSize);
  gap: var(--gapSize);
  align-items: center;
  justify-content: center;
  transform: skewX(22deg);
}

@keyframes loader-span {
  0% {
    transform: scaleY(0);
    opacity: 0.5;
    transform-origin: top;
  }
  40% {
    transform: scaleY(1);
    opacity: 1;
    transform-origin: top;
  }
  60% {
    transform: scaleY(1);
    opacity: 1;
    transform-origin: bottom;
  }
  100% {
    transform: scaleY(0);
    opacity: 0.5;
    transform-origin: bottom;
  }
}

.loader__span {
  height: 60%;
  background: currentColor;
  animation: loader-span 1s var(--bezierFastoutSlowin) infinite;
  transform: scaleY(0);
  transform-origin: top left
}

.loader__span:nth-child(2) {
    animation-delay: 0.1s;
  }

.loader__span:nth-child(3) {
    animation-delay: 0.2s;
  }

.button {
  --buttonSize: calc((56 / 16) * 1rem);
  --buttonFontSize: calc((18 / 16) * 1rem);
  --buttonFocusWidth: 4px;
  --buttonPadding: 0 var(--spaceL);
  --buttonTextColor: rgb(var(--rgbBackground));
  --buttonTextVisibility: visible;

  outline: none;
  height: var(--buttonSize);
  padding: var(--buttonPadding);
  cursor: pointer;
  transition-property: opacity, color, background;
  transition-duration: var(--durationS);
  transition-timing-function: var(--bezierFastoutSlowin);
  display: inline-flex;
  align-items: center;
  color: var(--buttonTextColor);
  position: relative;
  z-index: 1
}

.button:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
  }

.button::after {
    content: '';
    transition-property: opacity, color, background;
    transition-duration: var(--durationM);
    transition-timing-function: var(--bezierFastoutSlowin);
    background: rgb(var(--rgbPrimary));
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
  }

.button:active {
    box-shadow: none;
    transition-duration: calc(var(--durationXS) / 2);
  }

@media (prefers-reduced-motion: no-preference) {
    .button {
      transition-property: transform, opacity, color, background;
    }

    .button:hover {
      transform: scale(1.05);
    }

    .button:active {
      transform: scale(1);
    }
  }

.button--secondary {
  --buttonSpace: 10px;
  --buttonTextColor: rgb(var(--rgbPrimary));

  background: none;
  padding-left: var(--buttonSpace);
  padding-right: var(--buttonSpace);
  position: relative;
  left: calc(var(--buttonSpace) * -1);
  height: calc((32 / 16) * 1rem)
}

.button--secondary::after {
    content: '';
    height: calc((32 / 16) * 1rem);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(var(--rgbPrimary) / 0.2);
    transform: scale3d(0, 1, 1);
    transform-origin: right;
    -webkit-clip-path: none;
            clip-path: none;
  }

.button--secondary:hover {
    transform: none;
    background: transparent;
  }

.button--secondary:hover::after {
    transform: scale3d(1, 1, 1);
    transform-origin: left;
  }

@media (prefers-reduced-motion: no-preference) {
    .button--secondary::after {
      transition: transform var(--durationM) var(--bezierFastoutSlowin);
    }
  }

.button--loading {
  --buttonTextVisibility: hidden;
}

.button--icon-only {
  --buttonPadding: 0;
  --buttonTextColor: var(--colorTextBody);

  width: var(--buttonSize);
  align-items: center;
  justify-content: center;
  padding: 0
}

.button--icon-only:hover {
    transform: none;
  }

.button--icon-only::after {
    background: rgb(var(--rgbText) / 0);
  }

.button--icon-only:hover::after {
    background: rgb(var(--rgbText) / 0.1);
  }

.button__text {
  font-size: var(--buttonFontSize);
  font-weight: var(--fontWeightMedium);
  visibility: var(--buttonTextVisibility);
  position: relative;
  line-height: 1;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: rgb(var(--rgbBackground));
}

.button__icon {
  transition-property: opacity, fill;
  transition-duration: var(--durationS);
  transition-timing-function: var(--bezierFastoutSlowin)
}

@media (prefers-reduced-motion: no-preference) {
    .button__icon {
      transition-property: transform, opacity, fill;
    }
  }

.button__icon--start {
  margin-right: var(--spaceS);
}

.button__icon--end {
  margin-left: var(--spaceS);
}

@media (prefers-reduced-motion: no-preference) {
  .button:hover .button__icon--shift {
    transform: translate3d(var(--spaceXS), 0, 0);
  }
}

.button--loading .button__icon {
  opacity: 0;
}

.nav-toggle {
  --buttonSize: var(--space2XL);

  position: fixed;
  top: var(--spaceOuter);
  right: var(--spaceOuter);
  width: var(--buttonSize);
  height: var(--buttonSize);
  z-index: 1024;
  display: none
}

@media (max-width: 696px), (max-height: 696px) {
    .nav-toggle {
      display: flex;
    }
  }

.nav-toggle__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.nav-toggle__icon {
  --iconSize: var(--spaceXL);

  position: absolute;
  transition-property: opacity, fill;
  transition-duration: var(--durationM);
  transition-timing-function: var(--bezierFastoutSlowin);
  opacity: 1;
  transform: rotate(0deg);
  fill: var(--colorTextBody);
  width: var(--iconSize);
  height: var(--iconSize)
}

@media (prefers-reduced-motion: no-preference) {
    .nav-toggle__icon {
      transition-delay: 0.1s;
      transition-property: opacity, transform, fill;
    }
  }

.nav-toggle__icon--open {
  opacity: 0
}

@media (prefers-reduced-motion: no-preference) {
    .nav-toggle__icon--open {
      transition-delay: 0s;
      transform: rotate(45deg);
    }
  }

.nav-toggle__icon--close {
  opacity: 0
}

@media (prefers-reduced-motion: no-preference) {
    .nav-toggle__icon--close {
      transition-delay: 0s;
      transform: rotate(-45deg);
    }
  }

.nav-toggle__icon--open.nav-toggle__icon--close {
  opacity: 1
}

@media (prefers-reduced-motion: no-preference) {
    .nav-toggle__icon--open.nav-toggle__icon--close {
      transition-delay: 0.1s;
      transform: rotate(0deg);
    }
  }

.navbar {
  --headerNavFontSize: calc((16 / 16) * 1rem);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
  z-index: 1024;
  position: fixed;
  top: 0;
  height: 72px
}

@media (max-width: 696px) {
    .navbar {
      --headerNavFontSize: calc((22 / 16) * 1rem);
    }
  }

@media (max-width: 696px), (max-height: 696px) {
    .navbar {
      bottom: auto;
    }
  }

.nav-bar-has-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  background-color: rgb(var(--rgbBackground)) !important;
}

.navbar__logo {
  margin-right: 1.5rem;
  z-index: 16;
  padding-left: calc(var(--spaceXL) * 2)
}

@media (max-width: 696px) {
    .navbar__logo {
      padding-left: var(--spaceOuter);
    }
  }

.navbar__logo:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

.navbar__logo:active {
    box-shadow: none;
  }

.navbar__nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 auto;
  top: calc(var(--spaceM) * -1)
}

@media (max-width: 696px), (max-height: 696px) {
    .navbar__nav {
      display: none;
    }
  }

.navbar__nav-list {
  display: flex;
  flex-direction: row;
}

.navbar__nav-link {
  padding: var(--spaceM);
  color: rgb(var(--rgbText) / 0.6);
  font-weight: var(--fontWeightMedium);
  font-size: var(--headerNavFontSize);
  text-decoration: none;
  position: relative;
  transition: color var(--durationS) ease 0.1s;
  line-height: 1
}

.navbar__nav-link:hover,
  .navbar__nav-link:active,
  .navbar__nav-link:focus,
  .navbar__nav-link[aria-current='page'] {
    color: var(--colorTextBody);
  }

@media (prefers-reduced-motion: no-preference) {
    .navbar__nav-link::after {
      transition: transform var(--durationM) var(--bezierFastoutSlowin);
    }
  }

.navbar__nav-link:hover:after,
  .navbar__nav-link:active:after,
  .navbar__nav-link:focus:after,
  .navbar__nav-link[aria-current='page']:after {
    transform: scaleX(1) translateY(-2px);
    transform-origin: left;
  }

.navbar__nav-link:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

.navbar__nav-link:active {
    box-shadow: none;
  }

.navbar__nav-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 16
}

@media (max-width: 696px), (max-height: 696px) {
    .navbar__nav-icons {
      flex-direction: row;
      position: absolute;
      bottom: var(--spaceXL);
      left: var(--spaceXL);
    }
  }

@media (max-width: 820px) and (max-height: 420px) {
    .navbar__nav-icons {
      left: var(--spaceL);
      transform: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

.navbar__nav-icon-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spaceS);
  width: var(--space2XL);
  height: var(--space2XL);
  color: var(--colorTextLight);
  transition: color var(--durationM) var(--bezierFastoutSlowin)
}

.navbar__nav-icon-link:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

.navbar__nav-icon-link:active {
    box-shadow: none;
  }

.navbar__nav-icon-link:hover,
  .navbar__nav-icon-link:focus,
  .navbar__nav-icon-link:active {
    color: rgb(var(--rgbAccent));
  }

.navbar__nav-icon {
  fill: currentColor;
  transition: fill var(--durationM) ease;
}

.navbar__mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(var(--rgbBackground) / 0.9);
  transform: translate3d(0, -100%, 0);
  transition-property: background;
  transition-duration: var(--durationL);
  transition-timing-function: var(--bezierFastoutSlowin);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px)
}

@media (max-width: 696px), (max-height: 696px) {
    .navbar__mobile-nav {
      display: flex;
    }
  }

@media (prefers-reduced-motion: no-preference) {
    .navbar__mobile-nav {
      transition-property: transform, background;
      transform: translate3d(0, -100%, 0);
    }
  }

.navbar__mobile-nav--entered {
  transform: translate3d(0, 0, 0);
}

.navbar__mobile-nav-link {
  width: 100%;
  font-size: var(--headerNavFontSize);
  text-align: center;
  text-decoration: none;
  color: var(--colorTextBody);
  padding: var(--spaceL);
  opacity: 0;
  transition-property: opacity;
  transition-duration: var(--durationS);
  transition-timing-function: var(--bezierFastoutSlowin);
  position: relative;
  top: calc(var(--spaceM) * -1)
}

@media (prefers-reduced-motion: no-preference) {
    .navbar__mobile-nav-link {
      transition-property: transform, opacity;
      transform: translate3d(0, calc(var(--spaceXL) * -1), 0);
    }
  }

@media (max-width: 820px) and (max-height: 420px) {
    .navbar__mobile-nav-link {
      top: auto;
    }
  }

@media (max-width: 400px), (max-height: 360px) {
    .navbar__mobile-nav-link {
      --headerNavFontSize: calc((18 / 16) * 1rem);
    }
  }

.navbar__mobile-nav-link::after {
    content: '';
    position: absolute;
    top: 100%;
    right: var(--space3XL);
    left: var(--space3XL);
    height: 4px;
    background: rgb(var(--rgbAccent));
    transform: scaleX(0) translateY(-1px);
    transform-origin: right;
  }

@media (prefers-reduced-motion: no-preference) {
    .navbar__mobile-nav-link::after {
      transition: transform var(--durationM) var(--bezierFastoutSlowin);
    }
  }

.navbar__mobile-nav-link:hover:after,
  .navbar__mobile-nav-link:active:after,
  .navbar__mobile-nav-link:focus:after {
    transform: scaleX(1) translateY(-1px);
    transform-origin: left;
  }

.navbar__mobile-nav-link:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

.navbar__mobile-nav-link:active {
    box-shadow: none;
  }

.navbar__mobile-nav-link--entered {
  opacity: 1;
  transform: none;
}

.coming-soon-button {
  background-color: var(--palm-primary) !important;
  font-weight: 600 !important;
  font-size: .875rem !important;
  box-shadow: unset !important;
  color: black !important;
  text-transform: none !important;
  margin-right: 1rem;
}
body {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

input,
textarea {
  font-family: inherit;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
}

button {
  margin: 0;
  border: 0;
  font-family: inherit;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding-left: 1em;
}

body {
  font-family: 'Noto Sans Mono', sans-serif;
  font-weight: var(--fontWeightRegular);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  background: rgb(var(--rgbBackground));
  color: var(--colorTextBody);
  border: 0;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
}

::selection {
  background: rgb(var(--rgbAccent));
  color: rgb(var(--rgbBlack));
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app {
  width: 100%;
  position: relative;
  background: rgb(var(--rgbBackground));
  transition: background var(--durationM) ease;
  outline: none;
  display: grid;
  grid-template: 100% / 100%;
}

.app__page {
  opacity: 0;
  grid-area: 1 / 1;
  transition: opacity var(--durationS) ease;
  min-height: 100vh;
}

.app__page--exiting,
.app__page--entering {
  opacity: 0;
}

.app__page--entered {
  transition-duration: var(--durationL);
  transition-delay: var(--durationXS);
  opacity: 1;
}

.skip-to-main {
  color: rgb(var(--rgbBackground));
  z-index: 128
}

.skip-to-main:focus {
    padding: var(--spaceS) var(--spaceM);
    position: fixed;
    top: var(--spaceM);
    left: var(--spaceM);
    text-decoration: none;
    font-weight: var(--fontWeightMedium);
    line-height: 1;
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

.skip-to-main::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(var(--rgbPrimary));
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
    z-index: -1;
  }

