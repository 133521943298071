.navbar {
  --headerNavFontSize: calc((16 / 16) * 1rem);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
  z-index: 1024;
  position: fixed;
  top: 0;
  height: 72px;

  @media (--mediaMobile) {
    & {
      --headerNavFontSize: calc((22 / 16) * 1rem);
    }
  }

  @media (--mediaMobile), (max-height: 696px) {
    & {
      bottom: auto;
    }
  }
}

.nav-bar-has-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  background-color: rgb(var(--rgbBackground)) !important;
}

.navbar__logo {
  margin-right: 1.5rem;
  z-index: 16;
  padding-left: calc(var(--spaceXL) * 2);

  @media (--mediaMobile) {
    & {
      padding-left: var(--spaceOuter);
    }
  }

  &:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

  &:active {
    box-shadow: none;
  }
}

.navbar__nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 auto;
  top: calc(var(--spaceM) * -1);

  @media (--mediaMobile), (max-height: 696px) {
    & {
      display: none;
    }
  }
}

.navbar__nav-list {
  display: flex;
  flex-direction: row;
}

.navbar__nav-link {
  padding: var(--spaceM);
  color: rgb(var(--rgbText) / 0.6);
  font-weight: var(--fontWeightMedium);
  font-size: var(--headerNavFontSize);
  text-decoration: none;
  position: relative;
  transition: color var(--durationS) ease 0.1s;
  line-height: 1;

  &:hover,
  &:active,
  &:focus,
  &[aria-current='page'] {
    color: var(--colorTextBody);
  }

  @media (--mediaUseMotion) {
    &::after {
      transition: transform var(--durationM) var(--bezierFastoutSlowin);
    }
  }

  &:hover:after,
  &:active:after,
  &:focus:after,
  &[aria-current='page']:after {
    transform: scaleX(1) translateY(-2px);
    transform-origin: left;
  }

  &:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

  &:active {
    box-shadow: none;
  }
}

.navbar__nav-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 16;

  @media (--mediaMobile), (max-height: 696px) {
    & {
      flex-direction: row;
      position: absolute;
      bottom: var(--spaceXL);
      left: var(--spaceXL);
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      left: var(--spaceL);
      transform: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.navbar__nav-icon-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spaceS);
  width: var(--space2XL);
  height: var(--space2XL);
  color: var(--colorTextLight);
  transition: color var(--durationM) var(--bezierFastoutSlowin);

  &:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

  &:active {
    box-shadow: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: rgb(var(--rgbAccent));
  }
}

.navbar__nav-icon {
  fill: currentColor;
  transition: fill var(--durationM) ease;
}

.navbar__mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(var(--rgbBackground) / 0.9);
  transform: translate3d(0, -100%, 0);
  transition-property: background;
  transition-duration: var(--durationL);
  transition-timing-function: var(--bezierFastoutSlowin);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(16px);

  @media (--mediaMobile), (max-height: 696px) {
    & {
      display: flex;
    }
  }

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, background;
      transform: translate3d(0, -100%, 0);
    }
  }
}

.navbar__mobile-nav--entered {
  transform: translate3d(0, 0, 0);
}

.navbar__mobile-nav-link {
  width: 100%;
  font-size: var(--headerNavFontSize);
  text-align: center;
  text-decoration: none;
  color: var(--colorTextBody);
  padding: var(--spaceL);
  opacity: 0;
  transition-property: opacity;
  transition-duration: var(--durationS);
  transition-timing-function: var(--bezierFastoutSlowin);
  position: relative;
  top: calc(var(--spaceM) * -1);

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, calc(var(--spaceXL) * -1), 0);
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      top: auto;
    }
  }

  @media (max-width: 400px), (max-height: 360px) {
    & {
      --headerNavFontSize: calc((18 / 16) * 1rem);
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    right: var(--space3XL);
    left: var(--space3XL);
    height: 4px;
    background: rgb(var(--rgbAccent));
    transform: scaleX(0) translateY(-1px);
    transform-origin: right;
  }

  @media (--mediaUseMotion) {
    &::after {
      transition: transform var(--durationM) var(--bezierFastoutSlowin);
    }
  }

  &:hover:after,
  &:active:after,
  &:focus:after {
    transform: scaleX(1) translateY(-1px);
    transform-origin: left;
  }

  &:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

  &:active {
    box-shadow: none;
  }
}

.navbar__mobile-nav-link--entered {
  opacity: 1;
  transform: none;
}

.coming-soon-button {
  background-color: var(--palm-primary) !important;
  font-weight: 600 !important;
  font-size: .875rem !important;
  box-shadow: unset !important;
  color: black !important;
  text-transform: none !important;
  margin-right: 1rem;
}